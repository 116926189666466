<template>
  <div class="notifications">
    <TransitionGroup tag="div" name="notification" class="row gap-1">
      <div class="col-12" v-for="(item, idx) in notifications" :key="item">
        <div class="card">
          <div class="card-body">
            <h5 class="text-success p-0">
              <i class="fa fa-bell fa-shake mx-1"></i>{{ item.title }}
              <router-link class="btn btn-sm" :to="item.path">
                <button
                  class="btn btn-sm"
                  @click="notifications.splice(idx, 1)"
                >
                  <i class="fa fa-arrow-right"></i>
                </button>
              </router-link>
            </h5>
          </div>
        </div>
      </div>
    </TransitionGroup>
  </div>
</template>
  
  <script>
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import * as api from "../../utils/api";

export default {
  name: "FirebaseMessaging",

  data() {
    return {
      firebaseConfig: {
        apiKey: "AIzaSyAB__Uk6yky4dL7GJsl6xIavnSRs1dnzFU",
        authDomain: "insellombor.firebaseapp.com",
        projectId: "insellombor",
        storageBucket: "insellombor.appspot.com",
        messagingSenderId: "159842149525",
        appId: "1:159842149525:web:4e6aabd5be875a0e94e79c",
        measurementId: "G-4KT2TNN29X",
      },
      messaging: null,
      user: null,
      data: {
        id: 0,
        title: "",
        body: {},
        user_id: 0,
      },
      notifications: [
        // { title: "Yangi mijoz qo'shildi", path: "/spik" }
      ],
      fireToken: "",
    };
  },

  methods: {
    initializeFirebase() {
      const app = initializeApp(this.firebaseConfig);
      this.messaging = getMessaging(app);
      getToken(this.messaging, {
        vapidKey:
          "BBsLfvFV2X81F7UmrPzFXYTA5wD-062jyNLjExQK0LktphTPvNhUvAEHT9hHnhYtS4IgzGUBjX800K33zxgIw00",
      })
        .then((currentToken) => {
          if (currentToken) {
            this.fireToken = currentToken;
            this.get();
          } else {
            console.log(
              "No registration token available. Request permission to generate one."
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
      onMessage(this.messaging, (data) => {
        console.log(data);
        this.parseMessage(data.notification);
      });
    },
    get() {
      api.user(JSON.parse(localStorage["user_id"])).then((val) => {
        this.user = val;
        if (!this.user.firebase_token) {
          this.putUser(this.user);
        }
      });
    },
    putUser(user) {
      user.password = "";
      user.firebase_token = this.fireToken;
      console.log(user.firebase_token);

      api.updateUser(user).then(() => {
        this.initializeFirebase();
        // this.get();
      });
    },
    parseMessage(data) {
      const body = data.body;
      switch (data.title) {
        case "new_telegram_shop":
          this.data = {
            title: "Yangi mijoz qo'shildi",
            body: {
              id: body,
            },
            path: "/tg-mijozlar",
          };
          break;
        case "new_telegram_order":
          this.data = {
            title: "Yangi buyurtma qo'shildi",
            body: {
              id: body,
            },
            path: "/hodimTarix/0",
          };
          break;
        default:
          break;
      }

      this.notifications.push(this.data);
    },
  },

  mounted() {
    this.initializeFirebase();
  },
};
</script>
  
<style lang="scss" scoped>
.notifications {
  position: fixed;
  top: 2%;
  right: 2%;
  padding: 1rem;
  max-width: 400px;
  max-height: 500px;
  overflow: auto;
  z-index: 99;

  .card {
    font-size: small;
    box-shadow: 0 0 15px 1px gray;
  }
}

.notification-enter-from,
.notification-leave-to {
  opacity: 0;
}

.notification-enter-active,
.notification-leave-active {
  transition: all 0.5s;
}
</style>
  