<script>
export default {
  name: "pr_trans",
  emits: {
    end: null,
  },
  data() {
    return {
      deferredPrompt: null,
      revisionApp: JSON.parse(localStorage["RevisionApp"] || false) || false,
    };
  },
  computed: {},
  methods: {
    open(e) {
      this.deferredPrompt = e;
      document.querySelector(`[data-target="#openModal"]`).click();
    },
    downloadApp() {
      this.deferredPrompt.prompt();
      this.deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          localStorage.setItem(
            "RevisionApp",
            JSON.stringify(this.deferredPrompt)
          );
        }
        this.deferredPrompt = null;
        document.querySelector(`[close-modal]`).click();
      });
    },
    notDownload() {
      document.querySelector(`[close-modal]`).click();
      localStorage.setItem("RevisionApp", JSON.stringify(this.deferredPrompt));
    },
  },
  mounted() {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      this.deferredPrompt = e;

      if (
        !JSON.parse(localStorage["RevisionApp"] || false) &&
        this.$route.path !== "/login"
      ) {
        if (this.deferredPrompt) {
          setTimeout(() => {
            this.open(this.deferredPrompt);
          }, 1000);
        }
      }
    });
  },
};
</script>

<template>
  <btn
    color="green"
    data-toggle="modal"
    data-target="#openModal"
    v-show="false"
  >
    Ilova sifatida yuklash
  </btn>
  <modal id="openModal" size="xl">
    <template #header> </template>
    <template #body>
      <div class="card-body">
        <div class="gifs">
          <i class="fa-solid fa-computer text-primary com"></i>
          <i class="fa-solid fa-mobile-screen text-primary phone"></i>
        </div>
        <h2 class="text-center py-2">
          Qurilmangizga dastur sifatida o'rnatilsinmi ?
        </h2>
      </div>
      <div class="row">
        <div class="col text-center">
          <button class="btn btn-primary me-2" @click="downloadApp()">
            Ha
          </button>
          <button
            class="btn btn-danger"
            data-dismiss="modal"
            close-modal
            @click="notDownload()"
          >
            Yo'q
          </button>
        </div>
      </div>
    </template>
    <template #footer> </template>
  </modal>
</template>

<style scoped lang="scss">
.btn {
  font-size: 22px;
}

.gifs {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .phone {
    display: none;
    font-size: 100px;
  }

  @media (max-width: 450px) {
    .phone {
      display: block;
    }

    .com {
      display: none;
    }
  }

  i {
    font-size: 150px;
  }
}

@media (max-width: 450px) {
  .text-center {
    padding: 10px 0 !important;
  }
}

.btn_blue {
  background-color: var(--orangred);
  color: var(--white) !important;

  @media (max-width: 450px) {
    padding: 3px 10px !important;
  }

  &:focus {
    color: var(--white) !important;
  }
}

.btn_outline {
  border: 2px solid var(--orangred);
  color: var(--orangred);

  @media (max-width: 450px) {
    padding: 3px 10px !important;
  }

  &:focus {
    color: var(--orangred);
  }
}
</style>
