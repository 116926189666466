import router from "@/router";
import store from "@/store";
import axios from "axios";
import swal from "sweetalert";

async function api(endpoint, method, data) {
  store.dispatch("setloading", true);
  let result = null,
    error = null;
  await axios
    .create({
      baseURL: `https://insell-ombor.api-crud.uz/${endpoint}`,
      headers: {
        Authorization: `Bearer ${localStorage["token"]}`,
      },
    })
    .request({ method: method.toUpperCase(), data: data })
    .then((res) => {
      result = res.data;
    })
    .catch((err) => {
      error = err;
      catchError(err);
    })
    .finally(() => {
      store.dispatch("setloading", false);
    });
  return new Promise((resolve, reject) => {
    if (result) {
      resolve(result);
    } else {
      reject(error);
    }
  });
}

function catchError(error) {
  console.error(error);
  if (error.response?.status == 401) {
    swal({
      icon: "warning",
      title: "Ism yoki parolda xatolik !",
      timer: 2000,
    }).then(() => {
      if (location.pathname !== "/") {
        router.push("/");
      }
    });
  } else if (error.response?.status == 400) {
    swal({
      icon: "warning",
      title: error.response?.data.detail,
      timer: 2500,
    });
  } else if (error.response?.status == 404) {
    swal({
      icon: "warning",
      title: "URL manzili noto'g'ri !",
      timer: 2000,
    });
  } else if (error.response?.status == 422) {
    swal({
      icon: "warning",
      title: "Ma'lumotlar to'liq emas !",
      timer: 2000,
    });
  } else if (error.message == "Network Error") {
    swal({
      icon: "warning",
      title: "Server bilan aloqa mavjud emas !",
      timer: 2000,
    });
  }
}

export function success(target) {
  if (target !== undefined) {
    document.querySelector(`[${target}]`).click();
  }
  return swal({
    icon: "success",
    title: " ",
    buttons: false,
    timer: 800,
    closeOnEsc: false,
    closeOnClickOutside: false,
  });
}
export function error(target) {
  if (target !== undefined) {
    document.querySelector(`[${target}]`).click();
  }
  return swal({
    icon: "error",
    title: "Hodimni tanlang ",
    buttons: false,
    timer: 800,
    closeOnEsc: false,
    closeOnClickOutside: false,
  });
}

// token

export function token(data) {
  let form_data = new FormData();
  form_data.append("username", data.username);
  form_data.append("password", data.password);
  return api(`token`, `POST`, form_data);
}

// warehouse

export function warehouse(warehouse_id) {
  return api(`get_warehouse/${warehouse_id}`, "get");
}
export function warehouses(search) {
  let search_query = ``;
  if (search) search_query = `?search=${search}`;
  return api(`get_warehouses${search_query}`, "GET");
}
export function createWarehouse(data) {
  return api("create_warehouse", "post", data);
}
export function updateWarehouse(data) {
  return api("update_warehouse", "put", data);
}

export function get_warehouse_users(warehouse_id, user_id) {
  return api(`get_warehouse_users?warehouse_id=${warehouse_id || 0}&user_id=${user_id || 0}`, "get");
}

export function create_warehouse_user(data) {
  return api("create_warehouse_user", "post", data);
}
export function delete_warehouse_user(id) {
  return api(`delete_warehouse_user/${id}`, "delete");
}

// product

export function warehouseProducts(warehouse_id, search, quantity, page, limit, type, status, category_id) {
  let search_query = ``;
  if (search) search_query = `&search=${search}`;
  let quantity_query = ``;
  if (quantity) quantity_query = `&quantity=${quantity}`;
  let typeQuery = type ? `&type=${type}` : ``
  let Status = status ? `&status=${status}` : ``
  let cat_id = category_id ? `&category_id=${category_id}` : ``

  return api(
    `get_warehouse_products?warehouse_id=${warehouse_id}${search_query}${quantity_query}&page=${page}&limit=${limit}${typeQuery}${Status}${cat_id}`,
    "get"
  );
}
export function updateWarehouseProductPrice(data) {
  return api(`update_warehouse_product_price`, "put", data);
}

export function create_warehouse_product(data) {
  return api(`create_warehouse_product`, `POST`, data);
}

export function get_warehouse_product_compositions(id) {
  return api(`get_warehouse_product_compositions/${id}`, "get");
}
export function create_warehouse_product_compositions(data) {
  return api(`create_warehouse_product_compositions`, `POST`, data);
}

export function delete_warehouse_product_composition(id) {
  return api(`delete_warehouse_product_composition/${id}`, "delete");
}

export function made_product(data) {
  return api(`made_product`, `POST`, data);
}

export function get_product_made_histories(warehouse_pr_id, from_time, to_time,) {
  let time_query = ``;
  if (from_time && to_time)
    time_query = `from_time=${from_time}&to_time=${to_time}&`;
  return api(
    `get_product_made_histories?warehouse_pr_id=${warehouse_pr_id}&${time_query}`,
    "get"
  );
}

export function get_product_made_history_items(warehouse_pr_id,) {
  return api(
    `get_product_made_history_items/${warehouse_pr_id}`,
    "get"
  );
}

export function delete_made_product(id) {
  return api(`delete_made_product/${id}`, "delete");
}
// user

export function user(user_id) {
  return api(`get_user/${user_id}`, "get");
}

export function get_qarzdorliklar(user_id) {
  return api(`get_qarzdorliklar/${user_id}`, "get");
}

export function users(search, status, page, limit, role) {
  let search_query = ``;
  if (search) search_query = `search=${search}&`;
  return api(
    `get_users?${search_query}status=${status}&page=${page}&limit=${limit}${role ? `&role=${role}` : ``} `,
    `GET`
  );
}
export function createUser(data) {
  return api(`create_user`, `POST`, data);
}
export function updateUser(data) {
  return api(`update_user`, `PUT`, data);
}

// market
export function total_market(obj) {
  return api(`total_market?obj=${obj}`, "get");
}

export function market(market_id) {
  return api(`get_market/${market_id}`, "get");
}
export function markets(search, page, limit) {
  let search_query = ``;
  if (search) search_query = `search=${search}&`;
  return api(`get_markets?${search_query}page=${page}&limit=${limit}`, `GET`);
}
export function createMarket(data) {
  return api(`create_market`, `POST`, data);
}
export function updateMarket(data) {
  return api(`update_market`, `PUT`, data);
}

// shop

export function total_shop(obj) {
  return api(`total_shop?obj=${obj}`, "get");
}

export function shop(shop_id) {
  return api(`get_shop/${shop_id}`, "get");
}
export function shops(search, page, limit) {
  let search_query = ``;
  if (search) search_query = `search=${search}&`;
  return api(`get_shops?${search_query}page=${page}&limit=${limit}`, "get");
}
export function createShop(data) {
  return api(`create_shop`, "post", data);
}
export function updateShop(data) {
  return api(`update_shop`, "put", data);
}
export function takeMoneyFromShop(data) {
  return api(`take_money_from_shop`, "post", data);
}
export function shopIncomes(
  shop_id,
  from_time,
  to_time,
  page,
  limit,
  kassa_id
) {
  let time_query = ``;
  if (from_time && to_time)
    time_query = `from_time=${from_time}&to_time=${to_time}&`;
  return api(
    `get_shop_incomes?shop_id=${shop_id}&${time_query}page=${page}&limit=${limit}${kassa_id ? `&kassa_id=${kassa_id}` : ""
    }`,
    "get"
  );
}

export function get_shop_info(
  shop_id,
  from_time,
  to_time,
) {
  let time_query = ``;
  if (from_time && to_time)
    time_query = `${from_time}/${to_time}`;
  return api(
    `get_shop_info/${shop_id}/${time_query}`,
    "get"
  );
}

export function update_shop_user_agent(data) {
  return api(`update_shop_user_agent`, "put", data);
}

// order

export function orders(shop_id, user_id, status, page, limit, warehouse_id, agent_id, from_time, to_time) {
  let time_query = ``;
  if (from_time && to_time)
    time_query = `from_time=${from_time}&to_time=${to_time}&`;
  return api(
    `get_orders?shop_id=${shop_id}&user_id=${user_id}&status=${status}&page=${page}&limit=${limit}${warehouse_id ? `&warehouse_id=${warehouse_id}` : ``}${agent_id ? `&agent_id=${agent_id}&${time_query}` : ``}`,
    "get"
  );
}
export function orderBalance(order_id) {
  return api(`get_order_balances/${order_id}`, "get");
}
export function createOrder(data) {
  return api(`create_order`, "post", data);
}
export function removeOrder(id) {
  return api(`remove_order/${id}`, "delete");
}
export function confirmationOrder(data) {
  return api(`confirmation_order`, "put", data);
}

// trade

export function trades(
  order_id,
  warehouse_id,
  product_id,
  shop_id,
  page,
  limit
) {
  return api(
    `get_trades?order_id=${order_id}&warehouse_id=${warehouse_id}&w_p_id=${product_id}&shop_id=${shop_id}&page=${page}&limit=${limit}`,
    "get"
  );
}
export function toTrade(data) {
  return api("to_trade", "post", data);
}
export function updateTradePrice(related_number, price) {
  return api(`update_trade_price/${related_number}?price=${price}`, "post");
}
export function removeTrade(trade_id) {
  return api(`remove_trade/${trade_id}`, "delete");
}

// category

export function categories(search, page, limit, warehouse_name) {
  let search_query = ``;
  if (search) search_query = `search=${search}&`;
  let warehouseQuery = ``;
  if (warehouse_name) warehouseQuery = `&warehouse_name=${warehouse_name}`;
  return api(
    `get_categories?${search_query}page=${page}&limit=${limit}${warehouseQuery} `,
    `GET`
  );
}
export function createCategory(data) {
  return api(`create_category`, `POST`, data);
}
export function updateCategory(data) {
  return api(`update_category`, `PUT`, data);
}

// brand

export function brands(search, category_id, page, limit) {
  let search_query = ``;
  if (search) search_query = `search=${search}&`;
  return api(
    `get_brands?${search_query}category_id=${category_id}&page=${page}&limit=${limit} `,
    `GET`
  );
}
export function createBrand(data) {
  return api(`create_brand`, `POST`, data);
}
export function updateBrand(data) {
  return api(`update_brand`, `PUT`, data);
}

// currency

export function currencies() {
  return api(`get_currencies`, `GET`);
}
export function createCurrency(data) {
  return api(`create_currency`, `POST`, data);
}
export function updateCurrency(data) {
  return api(`update_currency`, `PUT`, data);
}

// expense

export function payForMarket(data) {
  return api(`pay_for_market_expense`, `POST`, data);
}
export function marketExpenses(
  market_id,
  from_time,
  to_time,
  page,
  limit,
  kassa_id
) {
  let time_query = ``;
  if (from_time && to_time)
    time_query = `from_time=${from_time}&to_time=${to_time}&`;
  return api(
    `get_market_expenses/${market_id}?${time_query}page=${page}&limit=${limit}${kassa_id ? `&kassa_id=${kassa_id}` : ""
    } `,
    "get"
  );
}
export function payForPartyExpense(data) {
  return api(`pay_for_party_expense`, `POST`, data);
}
export function partyExpenses(party_id, page, limit) {
  return api(
    `get_party_expenses/${party_id}?page=${page}&limit=${limit} `,
    "get"
  );
}

export function get_variable_expenses(
  from_time,
  to_time,
  page,
  limit,
  kassa_id
) {
  let time_query = ``;
  if (from_time && to_time)
    time_query = `from_time=${from_time}&to_time=${to_time}&`;
  return api(
    `get_variable_expenses?${time_query}page=${page}&limit=${limit}${kassa_id ? `&kassa_id=${kassa_id}` : ""
    } `,
    "get"
  );
}
export function pay_for_variable_expense(data) {
  return api(`pay_for_variable_expense`, `POST`, data);
}
// party

export function parties(status, market_id, page, limit) {
  return api(
    `get_parties?status=${status}&market_id=${market_id}&page=${page}&limit=${limit}`,
    "get"
  );
}
export function createParty(market_id) {
  return api(`create_party/${market_id}`, "post");
}
export function confirmationParty(data) {
  return api(`confirmation_party`, "put", data);
}
export function partyBalance(party_id) {
  return api(`get_party_balance/${party_id} `, "get");
}

// supply

export function supplies(
  market_id,
  warehouse_id,
  warehouse_product_id,
  page,
  limit
) {
  return api(
    `get_supplies?market_id=${market_id}&warehouse_id=${warehouse_id}&w_p_id=${warehouse_product_id}&page=${page}&limit=${limit}`,
    "get"
  );
}
export function takeSupply(data) {
  return api(`take_supply`, "post", data);
}
export function removeSupply(supply_id) {
  return api(`remove_supply/${supply_id}`, "delete");
}

// statistic

export function profitStatistic(from_time, to_time, shop_id) {
  let time_query = ``;
  if (from_time && to_time)
    time_query = `from_time=${from_time}&to_time=${to_time}&`;
  return api(`get_profit_statistics?${time_query}shop_id=${shop_id} `, "get");
}
export function productStatistic(from_time, to_time, limit) {
  let time_query = ``;
  if (from_time && to_time)
    time_query = `from_time=${from_time}&to_time=${to_time}&`;
  return api(`get_product_statistics?${time_query}limit=${limit}`, "get");
}
export function money() {
  return api(`get_money`, "get");
}

export function get_daily_statistics(p) {
  let time_query = ``;
  if (p.from_time && p.to_time)
    time_query = `from_time=${p.from_time}&to_time=${p.to_time}`;
  return api(`get_daily_statistics?${time_query}`, "get");
}
// kassa

export function get_kassa(id, currency_id, connected_user_id) {
  let ident = id ? `&ident=${id}` : "";
  let c_user_id =
    connected_user_id && localStorage["role"] !== "admin"
      ? `&connected_user_id=${connected_user_id}`
      : "";
  return api(
    `get_kassas?currency_id=${currency_id}${ident}${c_user_id}`,
    "get"
  );
}

export function create_kassa(data) {
  return api(`create_kassa`, "post", data);
}

export function update_kassa(data) {
  return api(`update_kassa`, "put", data);
}
export function get_kassa_users(id, connected_user_id) {
  return api(
    `get_kassa_users?kassa_id=${id}&connected_user_id=${connected_user_id}`,
    "get"
  );
}
export function create_kassa_user(data) {
  return api(`create_kassa_user`, "post", data);
}
export function delete_kassa_user(id) {
  return api(`delete_kassa_user/${id}`, "delete");
}
export function get_kassa_transfers(
  from_kassa,
  to_kassa,
  status,
  from_time,
  to_time,
  page,
  limit
) {
  let time_query = ``;
  if (from_time && to_time)
    time_query = `from_time=${from_time}&to_time=${to_time}&`;
  return api(
    `get_kassa_transfers?from_kassa_id=${from_kassa}&to_kassa_id=${to_kassa}&status=${status}&page=${page}&limit=${limit}${time_query}`,
    "get"
  );
}
export function create_kassa_transfer(data) {
  return api(`transfer_kassa`, "post", data);
}
export function delete_transfer_kassa(id) {
  return api(`delete_transfer_kassa/${id}`, "delete");
}
export function accept_transfer_kassa(id) {
  return api(`accept_transfer_kassa/${id} `, "post");
}

// transfer
export function get_transfers(p) {
  let time_query = ``;
  if (p.from_time && p.to_time)
    time_query = `from_time=${p.from_time}&to_time=${p.to_time}&`;
  return api(`get_transfers?${time_query}page=${p.page}&limit=${p.limit}&from_id=${p.from_id}&to_id=${p.to_id}${p.category_id ? `&category_id=${p.category_id}` : ``}${p.brand_id ? `&brand_id=${p.brand_id}` : ``} `, "get");
}

export function transfer(data) {
  return api(`transfer`, "post", data);
}

// return trade
export function return_trade(trade_id) {
  return api(`return_trade?trade_id=${trade_id}`, "delete");
}

export function archivetrades(order_id, page, limit) {
  return api(
    `get_archivetrades?order_id=${order_id}&page=${page}&=${limit}`,
    "get"
  );
}
// img
export function create_product_img(data) {
  let form_data = new FormData();
  form_data.append("file", data.file);
  form_data.append("source_id", data.source_id);
  form_data.append("source_id_2", data.source_id2);
  form_data.append("source", data.source);
  return api(`create_product_img`, "post", form_data);
}

export function delete_product_img(id) {
  return api(`delete_product_img/${id} `, "delete");
}

export function get_uploaded_files(source, source_id, source_id2) {
  return api(
    `get_uploaded_files/${source}/${source_id}/${source_id2 || 0} `,
    "get"
  );
}

// telegram
export function get_shops_from_telegram(p) {

  return api(`get_shops_from_telegram?page=${p.page}&limit=${p.limit}&status=${p.status}&search=${p.search}`, "get");
}

export function create_shop_from_telegram(data) {
  return api(`create_shop_from_telegram`, "post", data);
}
export function confirm_shop_from_telegram(id) {
  return api(`confirm_shop_from_telegram/${id}`, "put",);
}
// setting
export function get_settings() {
  return api(`get_settings`, "get");
}

export function update_settings(data) {
  return api(`update_settings`, "put", data);
}
